import React from "react";
import MyComponent from "../components/ComingSoonComponent";

function WIP() {
    return (
        <div className="WIP-container">
        <MyComponent />
        </div>
    );
}

export default WIP;
