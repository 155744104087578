import React from 'react';
import '../styles/ComingSoon.css';
import GR_logo from '../assets/GR_logo.svg';

const ComingSoon = () => {
  const handleEmailButtonClick = () => {
    window.location.href = 'mailto:info@sparktowers.com';
  };

  return (
    <div className="coming-soon-background-image">
      <div className="coming-soon-top-bar">
        <img src={GR_logo} alt="Logo" className="coming-soon-logo" />
        <button className="coming-soon-send-email-button" onClick={handleEmailButtonClick}>
          Get in touch
        </button>
      </div>
      <div className="coming-soon-center-text">Watch this space</div>
    </div>
  );
};

export default ComingSoon;
