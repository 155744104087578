import React from 'react';
import '../styles/Notify.css';
import ComingSoon from '../components/NotifyUpPart';
import NotifyBottomText from '../components/NotifyBottomText';
import HubSpotForm from '../components/HubspotFormComponent';

function Notify() {


  return (
    <div className="notify-container">
      <ComingSoon />
      <div className='NotifyBottomPart-div'>
        <NotifyBottomText />
        <HubSpotForm />
      </div>
    </div>
  )
}

export default Notify;