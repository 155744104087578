import React, { useEffect } from 'react';
import '../styles/HubspotFormComponent.css';


const HubspotForm = () => {

  // Load the HubSpot form when the component is mounted
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);

    // Create the HubSpot form when the script is loaded
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: '25880830',
          formId: '510b87f0-9647-4be2-bd48-15e742877ee7',
          target: '#hubspotForm',
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotForm;
