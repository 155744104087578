import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client'; 

import App from './App';
import Notify from './pages/Notify'; // Import du composant Notify
import WIP from './pages/WIP';

const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/notify" element={<Notify />} />
      <Route path="/wip" element={<WIP />} />
    </Routes>
  </Router>
);
