// DarkModeContext.js
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
      document.documentElement.classList.add('dark-mode');
      changeFavicon('favicon-dark.ico');
    } else {
      document.body.classList.remove('dark-mode');
      document.documentElement.classList.remove('dark-mode');
      changeFavicon('favicon-light.ico');
    }
  }, [darkMode]);

  const changeFavicon = (faviconPath) => {
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = faviconPath;
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = faviconPath;
      document.head.appendChild(newLink);
    }
  };

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

DarkModeProvider.propTypes = {
  children: PropTypes.node,
};