import React from 'react';
import '../styles/NotifyBottomText.css';


const NotifyBottomText = () => {
    
  return (
    <div className='NotifyBottom-div'>
      <h3 className="NotifyBottom-heading">Don’t miss out on our launch!</h3>
      <p className="NotifyBottom-paragraph">
        We respect your privacy. Your information will be kept confidential and
        will only be used to provide you with updates about our upcoming launch.
        Thank you for your interest in our platform. We&apos;re working hard to bring
        you the best in risk management technology. Stay tuned for more exciting
        updates!
      </p>
    </div>
  );
};

export default NotifyBottomText;
