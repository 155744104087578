import React from "react";
import ComingSoon from "./pages/Notify";
import { DarkModeProvider } from './contexts/DarkModeContext';
import DarkModeButton from './components/DarkModeButton';


function App() {
    return (
      <DarkModeProvider>
        <DarkModeButton />
        <div className="WIP-container">
          <ComingSoon />
        </div>
      </DarkModeProvider>
    );
}
export default App;
