import React, { useContext } from 'react';
import { DarkModeContext } from '../contexts/DarkModeContext';
import '../styles/DarkModeButton.css';
import '@siemens/ix/dist/siemens-ix/siemens-ix.css';
import { IxIcon } from '@siemens/ix-react';

const DarkModeButton = () => {
  const { darkMode, setDarkMode } = useContext(DarkModeContext);

  const handleToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <button className="dark-mode-button" onClick={handleToggle}>
      <IxIcon name="light-dark"/>
    </button>
  );
};

export default DarkModeButton;
